<template>
    <div>
        <v-overlay :value="overlay" absolute >
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
		<v-toolbar flat>
			<v-icon left>mdi-content-paste</v-icon>
			<v-toolbar-title>FORMULARIOS</v-toolbar-title>
			<v-divider class="mx-4" vertical></v-divider>			
			<v-spacer></v-spacer>
			<v-btn 
				color="blue" 
				small 
				dark 
				@click="openDialogFormulario"
			>
				<v-icon>mdi-plus</v-icon>Nuevo formulario
			</v-btn>
        </v-toolbar>
        <v-toolbar flat>
			<v-row>
				<v-col cols="12" md="6">
					<v-text-field
						clearable
						label="Nombre del Formulario"
						type="search"
						v-model="nombreFormulario"
					/>
				</v-col>
				<v-col cols="12" md="3">
					<v-select
						label="Servicios"
						clearable
						v-model="searchServicioCrmId"
						:items="servicios"
						item-text="nombre"
						item-value="servicio_crm_id"
						@change="getFormularios"
					/>
				</v-col>
				<v-col cols="12" md="3">
					<v-select
						label="Estado"
						clearable
						v-model="estado"
						:items="estados"
						item-text="description"
						item-value="status"
						@change="getFormularios"
					/>
				</v-col>
			</v-row>		
		</v-toolbar>
        <v-data-table
			:headers="headers"
			:items="dataTable"
			item-key="formulario_id"
			class="elevation-1"
		>
			<template v-slot:item.opcion="{item}">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-bind="attrs"
							v-on="on"
							icon
							x-large
							@click="visualizaFormulario(item)"
						>
						<v-icon color="success">mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>Previsualizar formulario</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-bind="attrs"
							v-on="on"
							icon
							x-large
							@click="editFormulario(item)"
						>
						<v-icon color="orange">mdi-content-paste</v-icon>
						</v-btn>
					</template>
					<span>Editar formulario</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<v-btn
							v-bind="attrs"
							v-on="on"
							icon
							x-large
							@click="updaEstadoFormulario(item)"
						>
						<v-icon :color="item.estado ? 'red' :'success'">{{item.estado ? 'mdi-cancel' : 'mdi-check'}}</v-icon>
						</v-btn>
					</template>
					<span>{{item.estado ? 'Deshabilitar' : 'Habilitar'}} formulario</span>
				</v-tooltip>
			</template>
		</v-data-table>
		<v-dialog 
			v-model="dialogFormulario" 
			fullscreen 
			persistent 
			background-color="white"
			:retain-focus="false"
			no-click-animation			
		>
			<v-overlay :value="overlay" absolute >
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</v-overlay>
			<v-toolbar 
				height="50" 
				class=""
				color="blue" 
				flat 
				dark
			>
				<v-btn 
					class="white--text" 
					text 
					small 
					@click="closeDialogFormulario"
				>
					<v-icon left>mdi-close</v-icon>
				</v-btn>
				<v-toolbar-title>
					<v-icon>mdi-content-paste</v-icon> CREAR NUEVO FORMULARIO
				</v-toolbar-title>
				<v-divider 
					class="mx-4" 
					inset 
					vertical
				/>
			</v-toolbar>			
			<v-card>
				<v-col cols="12">
					<v-row>
						<v-col md="9" style="position: fixed;z-index: 1;">
							<v-toolbar
								color="primary"	
								class="text-center white--text"							
								dense
							>
								<div class="d-flex justify-space-between align-center w-100">
									<div>
										ACCIONES
									</div>   
									<div class="d-flex align-center">
										<v-autocomplete
											v-model=uso
											:items=usos
											item-value="id"
											item-text="id"
											dense
											outlined
											label="Atar a:"
											:rules=required
											:hide-details="true"
											class="me-2 white"
											style="width: 150px;"
										/>
										<v-text-field 
											type="text" 
											name="" 
											id="" 
											outlined												
											label="Nombre del formulario"
											v-model="nombreFormulario"
											class="white text-center me-2" 
											:rules=required											
											:hide-details="true"
											dense
										/>
										<v-autocomplete												
											v-model=servicioCrmId
											:items=servicios
											item-value="servicio_crm_id"
											item-text="nombre"
											dense
											outlined
											label="Servicio"
											:hide-details="true"							
											class="me-2 white"
											@change="setSubServicios"		
											clearable					
										>
										</v-autocomplete>
										<v-menu 
											top
											right												
											:close-on-click="false"
											:close-on-content-click="false"
											v-model=vmenuSubServ
											:allow-overflow="true"												
										>
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													outlined
													class="btn-menu-ss"														
													v-bind="attrs"
													v-on="on"
													style=""										
												>
													<span></span>
													Sub serv. seleccionados {{ subServicioCrmId.length }}
													<v-icon right> mdi-menu-down </v-icon>
												</v-btn>
											</template>
											<v-card cols="12" style="height: 400px;overflow: auto;" >
												<v-col class="px-1 d-flex justify-space-between align-center text-center header-search-ss" >
													<v-col class="text-left"><b>SUB SERVICIOS:</b></v-col>
													<v-col>
														<v-text-field 
															type="text" 
															name="" 
															id="" 
															outlined												
															label="Buscar"
															v-model="searchSubServicio"
															class="white text-center me-2"								
															:hide-details="true"
															@keyup="searchSubServicios(false)"
															@click:clear="searchSubServicios(true)"
															dense
															clearable
														/>
													</v-col>
													<v-col>
														<v-btn
															@click="vmenuSubServ=false"
															color="default"
															dense
														>														
															<v-icon> mdi-cancel </v-icon> Cerrar 
														</v-btn>
													</v-col>
												</v-col>
												<v-col style="position: relative;top:85px">
													<v-row>														
														<v-col
															v-for="(subServ) in subServicios"
															:key="subServ.sub_servicio_crm_id"
															md="3"
															class="py-0"
														>
															<v-checkbox
																v-model="subServicioCrmId"
																:value="subServ.sub_servicio_crm_id"
																hide-details
																dense																
															>
																<template v-slot:label>
																	<span style="font-size: 12px;">
																		<b>{{ subServ.internal_name }}</b>
																	</span>
																</template>
															</v-checkbox>
														</v-col>
													</v-row>
												</v-col>
											</v-card>
										</v-menu>										
									</div>     										       										
								</div>
							</v-toolbar>
							<v-toolbar
								color="primary"	
								class="text-center white--text"							
								dense
							>
								<div class="d-flex justify-space-between align-center w-100">
									<div>
										FORMATO DE LA TABLA
									</div>   
									<div class="d-flex align-center">
										<v-text-field  
											type="number" 
											name="" 
											id="" 
											outlined
											min="1"  
											v-model="filas"
											class="white text-center" 
											style="width: 70px;"
											@keyup="filas = filas < 1 && filas != '' ? 1 : filas"
											:hide-details="true"
											dense
										/>
										x
										<v-text-field 
											type="number" 
											name="" 
											id="" 
											outlined
											min="1"  
											v-model="columnas"
											class="white text-center me-2" 
											style="width: 70px;"
											@keyup="columnas = columnas < 1 && columnas!= '' ? 1 : columnas"
											:hide-details="true"
											dense
										/>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn 
													v-bind="attrs"
													v-on="on"
													fab
													x-small
													color="warning"
													style="cursor: pointer"
													@click="agregaTabla"
												>
													<v-icon>mdi-plus</v-icon>
												</v-btn>
											</template>
											<span>Agregar tabla</span>
										</v-tooltip>
									</div>
								</div>
							</v-toolbar>
						</v-col>
						<v-col md="9" style="margin-top: 100px;">
							<v-card class="px-2 py-2">
								<v-col cols="12">
									<table
										class="tabla-formato my-3 w-100"										
										cellspacing="0"
										cellpadding="0"
										v-for="(tabla, t) in formato.tablas"
										:key="`tabla-${t}`"
										:id="`tabla-${t}`"
									>
										<thead>
											<tr>
												<th 
													:colspan="tabla.filas[0].columnas.length" 
													class="text-right"
												>													
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">																
															<v-icon 
																v-bind="attrs"
																v-on="on"
																color="error"					
																class="mx-2"												
																@click="formato.tablas = formato.tablas.filter(e => e.uuid != tabla.uuid)"
															>
																mdi-delete-forever
															</v-icon>																
														</template>
														<span>Eliminar tabla</span>
													</v-tooltip>
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<v-icon 
																v-bind="attrs"
																v-on="on"
																color="warning"		
																class="mx-2"	
																:disabled="t <= 0"
																@click="moverTabla('arriba',tabla, t)"
															>
																mdi-arrow-up
															</v-icon>																
														</template>
														<span>Mover</span>
													</v-tooltip>		
													<v-tooltip bottom>
														<template v-slot:activator="{ on, attrs }">
															<v-icon 
																v-bind="attrs"
																v-on="on"
																color="warning"		
																class="mx-2"	
																:disabled="(t+1) >= formato.tablas.length"																
																@click="moverTabla('abajo',tabla, t)"
															>
																mdi-arrow-down
															</v-icon>																
														</template>
														<span>Mover</span>
													</v-tooltip>																																			
												</th>
											</tr>
											<tr
												v-for="(columnas, f) in tabla.filas[0]"
												:key="`tr-thead-${t}-${f}`"
												:class="`tr-thead-${t}`"
											>
												<td	
													v-for="(col, c) in columnas"
													:key="`td-thead-${c}`"
													:id="`td-thead-${c}`"
													class="td-thead"
												>
													<div class="d-flex align-center justify-center">
														<div>OPCIONES</div>
														<div> 
															<v-menu 
																offset-y																
																:attach="`#td-thead-${c}`"
															>
																<template v-slot:activator="{ on, attrs }">
																	<v-icon 
																		color="primary"
																		@click="desplegarMenu"
																		v-bind="attrs"
																		v-on="on"
																	>
																		mdi-arrange-send-backward
																	</v-icon>																	
																</template>
																<v-list>
																	<v-list-item @click="accionTabla('agrega_columna_izquierda', t, c)">
																		<v-list-item-title class="subtitle-2">
																			<v-icon color="warning">mdi-table-column-plus-before</v-icon> Agregar columna 
																		</v-list-item-title>
																	</v-list-item>
																	<v-list-item @click="accionTabla('agrega_columna_derecha', t, c)">
																		<v-list-item-title class="subtitle-2">
																			<v-icon color="warning">mdi-table-column-plus-after</v-icon> Agregar columna 
																		</v-list-item-title>
																	</v-list-item>																	
																	<v-list-item @click="accionTabla('eliminar_columna', t, c)">
																		<v-list-item-title class="subtitle-2">
																			<v-icon color="warning">mdi-table-column-remove</v-icon> Eliminar columna
																		</v-list-item-title>
																	</v-list-item>
																	<v-list-item class="mt-3">
																		<v-list-item-title class="subtitle-2">																			
																			<v-icon color="primary">mdi-table-column-width</v-icon> Ancho columnas
																			<v-col class="col-12">
																				<v-row>
																					<v-col class="px-0">
																						<input 
																							type="number" 
																							name="" 
																							id=""
																							v-model="tamano"
																							class="input-tamano text-center"
																							@keyup="accionTabla('cambiar_ancho', t, c)"
																							@change="accionTabla('cambiar_ancho', t, c)"
																							@click="$event => $event.stopPropagation()"
																						>
																					</v-col>																					
																				</v-row>
																			</v-col>
																		</v-list-item-title>																		
																	</v-list-item>
																</v-list>
															</v-menu>															
														</div>
													</div>													
												</td>
											</tr>
										</thead>						
										<tbody>					
											<tr
												v-for="(fila, f) in tabla.filas"
												:key="`tabla-${t}-fila-${f}`"
												:class="`tr-table-${t}`"
											>
												<td	
													v-for="(columna, c) in fila.columnas"
													:key="`tabla-${t}-fila-${f}-columa-${c}`"
													:id="`tabla-${t}-fila-${f}-columa-${c}`"
													@dragover.prevent
                                                    @dragenter.prevent
													@drop="dropEvent($event, f, t, c)"
													:style="`width:${columna.width};height:${columna.height};background-color:${columna.backgroundTd}`"
												>
													<div class="d-flex">
														<div v-if="c == 0">
															<v-menu 
																offset-y																
																:close-on-content-click="false"
																:attach="`#tabla-${t}-fila-${f}-columa-${c}`"
															>
																<template v-slot:activator="{ on, attrs }">
																	<v-icon 
																		color="primary"
																		v-bind="attrs"
																		v-on="on"
																	>
																		mdi-arrange-send-backward
																	</v-icon>																	
																</template>
																<v-list>
																	<v-list-item @click="accionTabla('agrega_fila_arriba', t, f)">
																		<v-list-item-title class="subtitle-2">
																			<v-icon color="warning">mdi-table-row-plus-before</v-icon> Agregar fila 
																		</v-list-item-title>
																	</v-list-item>
																	<v-list-item  @click="accionTabla('agrega_fila_abajo', t, f)">
																		<v-list-item-title class="subtitle-2">
																			<v-icon color="warning">mdi-table-row-plus-after</v-icon>	Agregar fila 
																		</v-list-item-title>
																	</v-list-item>
																	<v-list-item @click="accionTabla('eliminar_fila', t, f)">
																		<v-list-item-title class="subtitle-2">
																			<v-icon color="warning">mdi-table-row-remove</v-icon> Eliminar fila
																		</v-list-item-title>
																	</v-list-item>
																	<v-list-item class="mt-3">
																		<v-list-item-title class="subtitle-2">																			
																			<v-icon color="primary">mdi-table-row-height</v-icon> Alto fila
																			<v-col class="col-12">
																				<v-row>
																					<v-col class="px-0">
																						<v-text-field
																							type="number"
																							dense
																							label=""
																							placeholder="%"
																							outlined
																							class="text-center"
																							style="width: 90px;"
																							v-model="tamano"
																							@keyup="accionTabla('cambiar_alto', t, c)"
																							@change="accionTabla('cambiar_alto', t, c)"
																							@click="$event => $event.stopPropagation()"
																						/>
																					</v-col>																					
																				</v-row>
																			</v-col>
																		</v-list-item-title>																		
																	</v-list-item>
																</v-list>
															</v-menu>
														</div>		
														<div>
															<v-menu 
																offset-y																
																:close-on-content-click="false"
																:attach="`#tabla-${t}-fila-${f}-columa-${c}`"
															>
																<template v-slot:activator="{ on, attrs }">
																	<v-icon 
																		color="cyan darken-2"
																		v-bind="attrs"
																		v-on="on"
																	>
																		mdi-opacity
																	</v-icon>																	
																</template>
																<v-list>
																	<v-list-item @click="verColorPickerCelda= !verColorPickerCelda">
																		<v-list-item-title class="subtitle-2">
																			<v-icon color="warning">mdi-tab-unselected</v-icon> Color celda 
																		</v-list-item-title>
																	</v-list-item>			
																	<v-list-item v-if="verColorPickerCelda">
																		<div class="text-center">
																			<v-color-picker
																				v-model= colorCelda
																				dot-size="20"
																				swatches-max-height="200"
																			/>
																			<v-btn
																				x-small
																				color="success"
																				@click="accionTabla('color_celda', t, f, c)"
																			>
																				Asignar
																			</v-btn>
																			<v-btn
																				x-small
																				color="default"
																				@click="verColorPickerCelda= false"
																			>
																				Cerrar
																			</v-btn>
																		</div>
																	</v-list-item>	
																	<v-list-item @click="verColorPickerInput= !verColorPickerInput">
																		<v-list-item-title class="subtitle-2">
																			<v-icon color="warning">mdi-compare</v-icon> Color campo 
																		</v-list-item-title>
																	</v-list-item>	
																	<v-list-item v-if="verColorPickerInput">
																		<div class="text-center">
																			<v-color-picker
																				v-model=colorInput
																				dot-size="20"
																				swatches-max-height="200"
																			/>
																			<v-btn
																				x-small
																				color="success"
																				@click="accionTabla('color_input', t, f, c)"
																			>
																				Asignar
																			</v-btn>
																			<v-btn
																				x-small
																				color="default"
																				@click="verColorPickerInput= false"
																			>
																				Cerrar
																			</v-btn>
																		</div>
																	</v-list-item>				
																	<v-list-item  @click="verColorPickerTexto= !verColorPickerTexto">
																		<v-list-item-title class="subtitle-2">
																			<v-icon color="warning">mdi-format-color-text</v-icon> Color texto 
																		</v-list-item-title>
																	</v-list-item>			
																	<v-list-item v-if="verColorPickerTexto">
																		<div class="text-center">
																			<v-color-picker
																				v-model= colorTexto
																				dot-size="20"
																				swatches-max-height="200"
																			/>
																			<v-btn
																				x-small
																				color="success"
																				@click="accionTabla('color_texto', t, f, c)"
																			>
																				Asignar
																			</v-btn>
																			<v-btn
																				x-small
																				color="default"
																				@click="verColorPickerTexto= false"
																			>
																				Cerrar
																			</v-btn>
																		</div>
																	</v-list-item>	
																</v-list>
															</v-menu>
															<v-menu 
																offset-y																
																:close-on-content-click="false"
																:attach="`#tabla-${t}-fila-${f}-columa-${c}`"
																v-model="dialogMenuAAimagen"
																v-if="columna.contenido_img != null"
															>
																<template v-slot:activator="{ on, attrs }">
																	<v-icon
																		color="warning darken-2"
																		v-bind="attrs"
																		v-on="on"
																	>
																		mdi-tooltip-image
																	</v-icon>																	
																</template>
																<v-list>
																	<v-list-item class="mt-3">
																		<v-list-item-title class="subtitle-2">																			
																			Alto/Ancho imagen
																			<v-col class="col-12">
																				<v-row>
																					<v-col class="px-0">
																						<v-text-field
																							type="number"
																							dense
																							label="Alto"
																							placeholder="px"
																							outlined
																							hide-details
																							class="text-center"
																							style="width: 90px;"
																							v-model="altoImagen"
																							@click="$event => $event.stopPropagation()"
																						/>
																					</v-col>	
																					<v-col class="px-0">
																						<v-text-field
																							type="number"
																							dense
																							label="Ancho"
																							placeholder="px"
																							outlined
																							hide-details
																							class="text-center"
																							style="width: 90px;"
																							v-model="anchoImagen"
																							@click="$event => $event.stopPropagation()"
																						/>
																					</v-col>																					
																				</v-row>
																			</v-col>
																		</v-list-item-title>																		
																	</v-list-item>
																	<v-col class="text-center">
																		<v-btn
																			x-small
																			color="success"
																			@click="accionTabla('tamano_imagen', t, f, c)"
																		>
																			Asignar
																		</v-btn>
																		<v-btn
																			x-small
																			color="default"
																			@click="dialogMenuAAimagen= false"
																		>
																			Cerrar
																		</v-btn>
																	</v-col>
																</v-list>
															</v-menu>
														</div>	
													</div>						
													<input
														v-if="columna.texto"																
														:style="`border: 1px solid silver;width:97%;color:${columna.colorText}`"
														type="text" 
														name=""
														placeholder="Texto plano"
														class="my-1 mx-1" 
														:id="`contenido-${t}-fila-${f}-columa-${c}-texto`"
														v-model="columna.contenido_texto"
														@dblclick="deleteWidget($event)"
													>
													<input
														v-if="columna.input"
														type="text" 
														:style="`border: 1px solid ${columna.mapeo_input == null ? 'red' : ''};background-color:${columna.backgroundInput};color:${columna.colorText};width:97%;`"
														name=""
														class="my-1 mx-1" 
														readonly
														placeholder="Entrada de texto"
														:id="`contenido-${t}-fila-${f}-columa-${c}-input`"
														v-model="columna.contenido_input"
														@dblclick="deleteWidget($event)"	
														:title="`${
															columna.mapeo_input == null
															? 'No se ha asignado un campo de mapeo'
															:  camposMapeo.find(e => [columna.mapeo_input].includes(e.mapeo_campo_formulario_id))?.nombre
														}`"										
													>
													<input
														v-if="columna.check"															
														:title="`
															${
																columna.mapeo_check == null
																? 'No se ha asignado un campo de mapeo'
																:  camposMapeo.find(e => [columna.mapeo_check].includes(e.mapeo_campo_formulario_id))?.nombre
															}
														`"
														type="checkbox" 
														name=""
														class="my-1 mx-1"
														:id="`contenido-${t}-fila-${f}-columa-${c}-checkbox`"
														v-model="columna.contenido_input"																
														@dblclick="deleteWidget($event)"														
													>		
													<input
														v-if="columna.radio"
														type="radio" 
														:id="`contenido-${t}-fila-${f}-columa-${c}-radio`"
														:name="`radio-${t}-fila-${f}`"
														class="my-1 mx-1"
														v-model="columna.contenido_input"																										
														@dblclick.prevent="deleteWidget($event)"
														:title="`
															${
																(columna.mapeo_radio == null)
																? 'No se ha asignado un campo de mapeo'
																:  camposMapeo.find(e => [columna.mapeo_radio].includes(e.mapeo_campo_formulario_id) )?.nombre
															}
														`"
													>		
													<textarea
														v-if="columna.textarea"
														:id="`contenido-${t}-fila-${f}-columa-${c}-textarea`"
														type="textarea" 
														name=""
														placeholder="Área de texto"
														class="my-1 mx-1"
														:style="`border: 1px solid ${columna.mapeo_textarea == null ? 'red' : ''};background-color:${columna.backgroundInput};color:${columna.colorText};width:97%`"														
														@dblclick="deleteWidget($event)"
														:title="`
															${
																columna.mapeo_textarea == null
																? 'No se ha asignado un campo de mapeo'
																:  camposMapeo.find(e => [columna.mapeo_textarea].includes(e.mapeo_campo_formulario_id) )?.nombre
															}
														`"
													></textarea>		
													<input 
														v-if="columna.fecha"
														:id="`contenido-${t}-fila-${f}-columa-${c}-fecha`"
														:style="`border: 1px solid ${columna.mapeo_fecha == null ? 'red' : ''};background-color:${columna.backgroundInput};color:${columna.colorText};width:97%`"
														type="date" 
														name=""
														class="my-1 mx-1"
														@dblclick="deleteWidget($event)"
														:title="`
														${
															columna.mapeo_fecha == null
															? 'No se ha asignado un campo de mapeo'
															:  camposMapeo.find(e => [columna.mapeo_fecha].includes(e.mapeo_campo_formulario_id) )?.nombre
														}
														`"
													>
													<input
														v-if="columna.imagen"
														:id="`contenido-${t}-fila-${f}-columa-${c}-imagen`"
														type="file" 
														name=""
														style="width: 97%;"
														class="my-1 mx-1"
														@dblclick.prevent="deleteWidget($event)"
														@change="previsualizarImg($event, t, f, c)"
													>	
													<img
														v-if="columna.contenido_img != null"
														:id="`contenido-${t}-fila-${f}-columa-${c}-img-imagen`" 
														:src="`${columna.contenido_img.split(':')[0] == 'data' ?  columna.contenido_img : `${urlApi}img/formularios/${columna.contenido_img}`}`"
														:style="`width:${columna.ancho_imagen != 0 ? columna.ancho_imagen : '250'}px;height:${columna.alto_imagen != 0 ? columna.alto_imagen : '250'}px`"
														@dblclick="deleteWidget($event)"
													/>
													<v-autocomplete
														v-if="columna.campo_predeterminado"																							
														:items=camposPredeterminados
														v-model="columna.mapeo_predeterminado"
														:id="`contenido-${t}-fila-${f}-columa-${c}-campo-predeterminado`"
														item-value="tag"
														item-text="nombre"
														dense
														outlined
														label="CAMPO PREDETERMINADO"												
														:hide-details="true"
														class="white"
														@dblclick.prevent="deleteWidget($event)"
													/>
													<v-autocomplete
														v-if="columna.combo_dinamico"																								
														:items="mapeoComboDinamico"
														v-model="columna.mapeo_combo_dinamico"
														:id="`contenido-${t}-fila-${f}-columa-${c}-combo-dinamico`"
														item-value="tag"
														item-text="nombre"
														dense
														outlined
														label="DATOS DINÁMICOS"
														:hide-details="true"
														class="me-2 white mt-1"
														clearable
														@dblclick.prevent="deleteWidget($event)"
													/>
												</td>
											</tr>
										</tbody>										
									</table>									
								</v-col>
								<v-col cols="12" class="text-center">
									<v-btn 
										color="success"
										@click="storeFormulario"
									>
										<v-icon>mdi-floppy</v-icon> Guardar 
									</v-btn>
								</v-col>     
							</v-card>
						</v-col>
						<v-col md="3" style="position: fixed;right: 21px;">
							<v-card class="px-2 py-2" id="div-widgets">
								<v-toolbar
									color="primary"	
									class="text-center white--text"							
									dense
								>
									<div class="d-flex justify-space-between align-center w-100">										
										WIDGETS
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-btn 
													v-bind="attrs"
													v-on="on"
													fab
													x-small
													color="warning"												
													@click="formCampoMapeo"
												>
													<v-icon>mdi-plus</v-icon>
												</v-btn>
											</template>
											<span>Agregar campo de mapeo</span>
										</v-tooltip>
									</div>
								</v-toolbar>
								<v-col cols="12">
									<v-row>
										<v-col md="12">
											<v-card 
												:outlined="true" 
												class="my-1 d-flex align-center justify-center pointer-grab" 
												draggable
												@dragend="dragEnd($event)"
												id="campo_predeterminado"
												style="height: 40px;"												
											>
												<v-autocomplete																									
													:items=camposPredeterminados
													v-model="mapCampPredeterminado"
													item-value="tag"
													item-text="nombre"
													dense
													outlined
													label="CAMPO PREDETERMINADO"												
													:hide-details="true"
													class="white"
												/>
											</v-card>
										</v-col>							
									</v-row>
									<v-row>
										<v-col md="12">
											<v-card 
												:outlined="true" 
												class="my-1 d-flex align-center justify-center pointer-grab" 
												draggable
												@dragend="dragEnd($event)"
												id="texto"
												style="height: 40px;"												
											>
												<v-icon color="primary">mdi-format-text</v-icon> TEXTO PLANO
											</v-card>
										</v-col>							
									</v-row>
									<v-row>
										<v-col md="6" class="pt-2">
											<v-card 
												:outlined="true" 
												class="my-1 d-flex align-center justify-center pointer-grab" 
												draggable
												@dragend="dragEnd($event)"
												id="input"
												style="height: 40px;font-size: 14px;"
											>
												<v-icon color="primary">mdi-pencil</v-icon> ENTRADA DE TEXTO
											</v-card>
										</v-col>
										<v-col md="6">
											<v-autocomplete																									
												:items="mapeo"
												v-model="mapInput"
												item-value="mapeo_campo_formulario_id"
												item-text="nombre"
												dense
												outlined
												label="Mapeo"												
												:hide-details="true"
												class="me-2 white"
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col md="6" class="pt-2">
											<v-card 
												:outlined="true" 
												class="my-1 d-flex align-center justify-center pointer-grab" 
												draggable
												@dragend="dragEnd($event)"
												id="check"
												style="height: 40px;"
											>
												<v-icon color="primary">mdi-checkbox-marked-outline</v-icon> CHECK 
											</v-card>
										</v-col>
										<v-col md="6">
											<v-autocomplete																									
												:items="mapeo"
												v-model="mapCheck"
												item-value="mapeo_campo_formulario_id"
												item-text="nombre"
												dense
												outlined
												label="Mapeo"												
												:hide-details="true"
												class="me-2 white"
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col md="6" class="pt-2">
											<v-card 
												:outlined="true" 
												class="my-1 d-flex align-center justify-center pointer-grab" 
												draggable
												@dragend="dragEnd($event)"
												id="radio"
												style="height: 40px;"
											>
												<v-icon color="primary">mdi-radiobox-marked</v-icon> RADIO 
											</v-card>
										</v-col>
										<v-col md="6">
											<v-autocomplete																									
												:items="mapeo"
												v-model="mapRadio"
												item-value="mapeo_campo_formulario_id"
												item-text="nombre"
												dense
												outlined
												label="Mapeo"												
												:hide-details="true"
												class="me-2 white"
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col md="6" class="pt-2">
											<v-card 
												:outlined="true" 
												class="my-1 d-flex align-center justify-center pointer-grab" 
												draggable
												@dragend="dragEnd($event)"
												id="textarea"
												style="height: 40px;"
											>
												<v-icon color="primary">mdi-tooltip-text</v-icon> TEXT AREA 
											</v-card>
										</v-col>
										<v-col md="6">
											<v-autocomplete																									
												:items="mapeo"
												v-model="mapTextArea"
												item-value="mapeo_campo_formulario_id"
												item-text="nombre"
												dense
												outlined
												label="Mapeo"												
												:hide-details="true"
												class="me-2 white"
											/>
										</v-col>
									</v-row>
									<v-row>
										<v-col md="6" class="pt-2">
											<v-card 
												:outlined="true" 
												class="my-1 d-flex align-center justify-center pointer-grab" 
												draggable
												@dragend="dragEnd($event)"
												id="fecha"
												style="height: 40px;"
											>
												<v-icon color="primary">mdi-calendar</v-icon> FECHA
											</v-card>
										</v-col>
										<v-col md="6">
											<v-autocomplete																									
												:items="mapeo"
												v-model="mapFecha"
												item-value="mapeo_campo_formulario_id"
												item-text="nombre"
												dense
												outlined
												label="Mapeo"												
												:hide-details="true"
												class="me-2 white"
											/>
										</v-col>
									</v-row>
									<v-card
										:outlined="true" 
										class="mt-5 d-flex align-center justify-center" 
										draggable
										@dragend="dragEnd($event)"
										id="imagen"
										style="height: 40px;"
									>
										<v-icon color="primary">mdi-image-area</v-icon> IMÁGEN
									</v-card>
									<v-card 
										:outlined="true" 
										class="my-1 d-flex align-center justify-center pointer-grab" 
										draggable
										@dragend="dragEnd($event)"
										style="height: 50px;"					
										id="combo-dinamico"							
									>
										<v-autocomplete																									
											:items="mapeoComboDinamico"
											v-model="comboDinamicoId"
											item-value="mapeo_campo_formulario_id"
											item-text="nombre"
											dense
											outlined
											label="DATOS DINÁMICOS"
											:hide-details="true"
											class="me-2 white mt-1"
											clearable
										>
											<template v-slot:prepend-inner>
												<v-icon color="success">mdi-format-list-checks</v-icon>
											</template>
											<template v-slot:append-outer>
												<v-btn 
													color="success" 
													fab 
													small
													@click="openDialogComboDinamico"
												>
													<v-icon>mdi-format-list-checks</v-icon>
												</v-btn>
											</template>
										</v-autocomplete>
									</v-card>
								</v-col>
							</v-card>
						</v-col>
					</v-row>
				</v-col>
			</v-card>
		</v-dialog>
		<v-dialog 
			v-model="dialogCampoMapeo" 
			width="500px" 
			persistent 
			background-color="white"
			:retain-focus="false"
			no-click-animation			
		>
			<v-card>
				<v-overlay 
					:value="overlay" 
					absolute
				>
				<v-progress-circular indeterminate size="64"></v-progress-circular>
				</v-overlay>
				<v-form ref='form_campo_mapeo'>
					<v-col cols=12>
						<v-text-field
							label="Nombre del campo de mapeo"
							v-model="campoMapeo"
							:rules="required"
						/>				
					</v-col>
					<v-col>
						<v-btn 
							color="green" 
							small 
							dark
							@click="storeCampoMapeo" 
							class="mx-2"
						>
							<v-icon left small>mdi-content-save</v-icon>Guardar
						</v-btn>
						<v-btn 
							text 
							color="blue"
							small 
							@click="dialogCampoMapeo = false" 
							class="mx-2"
						>
							<v-icon left small>mdi-cancel</v-icon> Cancelar
						</v-btn>
					</v-col>
				</v-form>	
			</v-card>		
		</v-dialog>
		<v-dialog 
            v-model="dialogVisualizaFormulario"              
            persistent
            fullscreen 
            :retain-focus="false"            
            :no-click-animation="true"
        >
            <v-card>
                <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64" />
                </v-overlay>
                <VisualizaFormularioComponent 
                    v-if="dialogVisualizaFormulario"
                    :formulario=formularioSeleccionado
                    :nuevo = false
                    :editar= false
                    @close="dialogVisualizaFormulario =false"
                />
            </v-card>          
        </v-dialog>
		<v-dialog 
            v-model="dialogCampoDinamico"              
            persistent
            width="600px" 
            :retain-focus="false"            
            :no-click-animation="true"
        >
			<v-overlay :value="overlay">
				<v-progress-circular indeterminate size="64" />
			</v-overlay>
            <v-card>
				<v-form ref="campo_combo_dinamico">
					<v-alert type="success">COMBO DINAMICO {{ nombreComboDinamico }}</v-alert>
					<v-col cols="12">
						<v-col>
							<v-text-field
								label="Nombre del combo"
								outlined
								dense
								hide-details="auto"
								v-model="nombreComboDinamico"
								:rules="required"
							/>
						</v-col>
						<v-col cols="12">
							<v-row>
								<v-col cols="9">
									<v-text-field
										type="text"
										outlined
										dense
										hide-details
										label="Valor"
										v-model="valorComboDinamico"
									/>
								</v-col>
								<v-col cols="3" class="text-center">
									<v-btn 
										color="success" 
										@click="guardaComboDinamico"
									>
										Guardar
									</v-btn>
								</v-col>
							</v-row>
						</v-col>
						<v-col>	
							<v-alert type="success" dense>VALORES GUARDADOS</v-alert>
						</v-col>
						<v-col>
							<v-row>
								<v-col cols="12">
									<v-checkbox
										hide-details
										class="my-2"
										:label="`Orden ${tipoOrdenComboDinamico ? 'Descendente' : 'Ascendente'}`"
										v-model="tipoOrdenComboDinamico"
									>
									</v-checkbox>
								</v-col>
								<v-col
									v-for="(dcd, x) in this.detalleComboDinamico"
									:key="x"
									md="4"
									class="py-1"
								>
									<v-checkbox
										v-model="dcd.usable"
										:value="dcd.usable"
										:checked="dcd.usable"
										hide-details
										class="mt-0"
										:label="dcd.nombre"
										@click="updateDetalleCampoFormulario(dcd)"
									>
										<template v-slot:prepend>
											<v-icon 
												color="error"
												@click="deleteDetalleCampoFormulario(dcd)"
											>
												mdi-delete-forever
											</v-icon>
										</template>
									</v-checkbox>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" class="text-center">
							<v-btn
								@click="closeDialogComboDinamico"
								color="default"
								dense
							>														
								<v-icon> mdi-cancel </v-icon> Cerrar 
							</v-btn>
						</v-col>
				</v-col>		
				</v-form>		
            </v-card>          
        </v-dialog>
    </div>
</template>

<script>

	import { mapState, mapMutations, mapActions } from "vuex"
	import Vue from "vue"
	import VisualizaFormularioComponent from './VisualizaFormularioComponent.vue'

    export default {
        name: 'FormularioComponent',
		components:{
        	VisualizaFormularioComponent
    	},
        data:() => ({
            overlay: false,
            dialogFormulario: false,
			dialogVisualizaFormulario:false,
			dialogCampoDinamico:false,
            nombreFormulario:null,
            estado:true,
			subServicioCrmId:[],
			originalSubServicios:[],
			formularioSeleccionado:{},
			formularioId:null,
			servicioCrmId:null,
			searchServicioCrmId:null,
			searchSubServicio:'',
			vmenuSubServ:false,
			dialogMenuAAimagen:false,
			tamano:0,			
			colorCelda: '#fff',
			servicios:[],
			subServicios:[],
			subServiciosOriginal:[],
            dataTable:[],
			camposMapeo:[],
			camposDinamicos:[],
			detalleComboDinamico:[],
			datosComboDinamico:[{
				value1: null,
				value2: null
			}],
			usos:[
				{id:'ORDEN'},
				{id:'CITA'}
			],
			uso:null,
			mapCampPredeterminado:null,
			comboDinamicoId:null,
			comboDinamicoNumerico:false,
			mapComboDinamico: null,
			nombreComboDinamico:null,
			valorComboDinamico:null,
			filas:1,
			columnas:1,
            required: [(v) => !!v || "El campo es requerido"],
            estados:[
                {description: 'Habilitado', status: true},
                {description: 'Deshabilitado', status: false}
            ],            
            headers: [
                { text: "ID", align: "start", value: "formulario_servicio_crm_id" },
                { text: "Nombres", align: "start", value: "nombre" },
                { text: "Estado", align: "start", value: "estado" },
                { text: "Creado el", align: "start", value: "created_stamp" },
                { text: "Opciones", align: "start", value: "opcion" }			
            ],
			formato:{
				tablas:[]
			},
			dialogCampoMapeo:false,
			tablaSelec:null,
			filaSelec:null,
			columnaSelec:null,
			campoMapeo:null,
			mapInput:null,
			mapFecha:null,
			mapCheck:null,
			mapTextArea:null,
			mapRadio:null,
			pressed :false,
			start : undefined,
			startX: null,
			startWidth:null,
			verColorPickerCelda:false,
			verColorPickerInput:false,
			tipoOrdenComboDinamico:false,
			verColorPickerTexto:null,
			colorInput: '#fff',
			colorCelda: '#fff',
			colorTexto: '#fff',
			altoImagen:0,
			anchoImagen:0,
			required:[
				v => !!v || 'El campo es requerido'
			],
        }),
        computed: {

            ...mapState("master", ["loadingBtn", "companies","urlApi","loadingTable", "tenantId", "user"]),

            ...mapState("crm", ["loadingBtn", "paramAlertQuestion"]),

			mapeo: function(){

				return this.camposMapeo.filter(e => e.tipo == 'DINAMICO')

			},

			camposPredeterminados: function(){

				return this.camposMapeo.filter(e => e.tipo == 'PREDETERMINADO')

			},

			mapeoComboDinamico: function(){

				return this.camposMapeo.filter(e => e.tipo == 'MAPEO_COMBO_DINAMICO')

			},

        },
        methods:{

            ...mapMutations("master", ["setUrl","setLoadingTable","setTitleToolbar","setTenantId"]),

            ...mapActions("master", ["requestApi", "alertNotification"]),

            getFormularios(){

				this.setUrl('formularios')
				this.overlay=true

				this.requestApi({
					method: 'GET',
					data: {
						tenantId: this.tenantId,						
						typeList: 'formularios',
						searchServicioCrmId: this.searchServicioCrmId,
						estado: this.estado
					}
				}).then(res =>{

					console.log('res',res)

					this.servicios = res.data._embedded.formularios[0]
					this.subServicios = res.data._embedded.formularios[1]
					this.subServiciosOriginal = res.data._embedded.formularios[1]
					this.originalSubServicios = res.data._embedded.formularios[1]
					this.dataTable = res.data._embedded.formularios[2]
					
					this.overlay= false

				}).catch(()=>{

					this.overlay=false

				})

            },

            openDialogFormulario(){

				this.setUrl('formularios')
				this.overlay=true

				this.requestApi({
					method: 'GET',
					data: {
						tenantId: this.tenantId,						
						typeList: 'subServicios'
					}
				})
				.then(res =>{

					console.log('res',res)
					this.comboDinamicoId= null
					this.subServicios = res.data._embedded.formularios
					this.subServiciosOriginal= res.data._embedded.formularios
					this.dialogFormulario = true
					this.overlay= false
					this.getCamposMapeo()

				}).catch(()=>{

					this.overlay=false

				})

            },

			closeDialogFormulario(){

				this.dialogFormulario = false
				this.formato= { tablas:[] }
				this.servicioCrmId = null
				this.subServicioCrmId = []
				this.formularioId= null
				this.nombreFormulario = null
				this.uso = null

			},

			agregaTabla(){

				let filas = []				

				for (let index = 0; index < this.filas; index++) {
					
					filas.push({columnas : []})
					
					for (let index2 = 0; index2 < this.columnas; index2++) {
						
						filas[index].columnas.push({
							texto: false,
							contenido_texto:null,
							input:false,
							contenido_input:null,
							check: false,
							check_checked:false,
							radio_checked:false,
							radio:false,
							fecha:false,
							textarea:false,
							imagen:false,
							contenido_img:null,
							mapeo_input:null,
							mapeo_check:null,
							mapeo_radio:null,
							mapeo_fecha:null,
							mapeo_textarea:null,
							campo_predeterminado: false,
							mapeo_predeterminado:null,
							width:'0px',
							height:'0px',
							backgroundTd: '#fff',
							backgroundInput: '#fff',
							colorText: '#000',
							combo_dinamico:false,
							mapeo_combo_dinamico:null,
							combo_dinamico_seleccionado:null,
							nombre_combo_dinammico:null,
							alto_imagen:0,
							ancho_imagen:0
						})
					
					}				
					
				}

				this.formato.tablas.push({
					uuid: Math.random().toString(36).substr(0, 20),
					filas
				})

				this.columnas = 1 
				this.filas = 1

			},

			dropEvent(e,f,t,c){
				
				e.preventDefault()

				this.tablaSelec = t
				this.filaSelec = f
				this.columnaSelec = c		
				e.target.style.width = '50px'

				let cantTr = document.getElementsByClassName(`tr-table-${t}`)
					
				let arrTrs = Array.from(cantTr)

				for (let tr = 0; tr < arrTrs.length; tr++){

					let td = arrTrs[tr].getElementsByTagName('td')[c]

					td.style.width = '50px'

					this.formato.tablas[t].filas[tr].columnas[c].width = '50px'

				}
					
			},

			dragEnd(e){

				console.log(e.target.id)
				if(this.tablaSelec != null && this.filaSelec != null && this.columnaSelec != null){

					if(e.target.id == 'texto'){

						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].texto = true

					}else if(e.target.id == 'input'){

						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].input = true
						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].mapeo_input = this.mapInput

					}else if(e.target.id == 'check'){

						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].check = true
						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].mapeo_check = this.mapCheck

					}else if(e.target.id == 'radio'){

						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].radio = true
						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].mapeo_radio = this.mapRadio

					}else if(e.target.id == 'fecha'){

						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].fecha = true
						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].mapeo_fecha = this.mapFecha

					}else if(e.target.id == 'textarea'){
		
						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].textarea = true
						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].mapeo_textarea = this.mapTextArea

					}else if(e.target.id == 'imagen'){

						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].imagen = true
						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].contenido_img = null

					}else if(e.target.id == 'campo_predeterminado'){

						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].campo_predeterminado = true
						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].mapeo_predeterminado = this.mapCampPredeterminado

					}else if(e.target.id == 'combo-dinamico'){

						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].combo_dinamico = true
						this.formato.tablas[this.tablaSelec].filas[this.filaSelec].columnas[this.columnaSelec].mapeo_combo_dinamico = this.mapComboDinamico

					}

					this.tablaSelec = null
					this.filaSelec= null
					this.columnaSelec = null
				
					this.mapInput = null
					this.mapFecha = null
					this.mapCheck = null
					this.mapTextArea = null
					this.mapRadio = null
					this.mapCampPredeterminado = null
					this.mapComboDinamico = null

				}

			},

			deleteWidget(e){

				e.preventDefault()
				e.stopPropagation()

				console.log('e',e)

				let arrId = e.target.id.split('-')
				let tipo = arrId[arrId.length-1]
				console.log('tipo',tipo)
				let t = arrId[1]
				let f = arrId[3]
				let c = arrId[5]
	
				if(tipo == 'texto'){

					this.formato.tablas[t].filas[f].columnas[c].texto =false
					this.formato.tablas[t].filas[f].columnas[c].contenido_texto = null

				}else if(tipo == 'input'){

					this.formato.tablas[t].filas[f].columnas[c].input =false
					this.formato.tablas[t].filas[f].columnas[c].contenido_input = null

				}else if(tipo == 'checkbox'){

					this.formato.tablas[t].filas[f].columnas[c].check =false

				}else if(tipo == 'radio'){

					this.formato.tablas[t].filas[f].columnas[c].radio =false

				}else if(tipo == 'fecha'){

					this.formato.tablas[t].filas[f].columnas[c].fecha =false

				}else if(tipo == 'textarea'){

					this.formato.tablas[t].filas[f].columnas[c].textarea = false

				}else if(tipo == 'imagen'){

					this.formato.tablas[t].filas[f].columnas[c].imagen = false
					this.formato.tablas[t].filas[f].columnas[c].contenido_img = null

				}else if(e.target.id == `contenido-${t}-fila-${f}-columa-${c}-campo-predeterminado`){

					this.formato.tablas[t].filas[f].columnas[c].campo_predeterminado = false
					this.formato.tablas[t].filas[f].columnas[c].mapeo_predeterminado = null

				}else if(e.target.id == `contenido-${t}-fila-${f}-columa-${c}-combo-dinamico`){

					this.formato.tablas[t].filas[f].columnas[c].combo_dinamico = false
					this.formato.tablas[t].filas[f].columnas[c].mapeo_combo_dinamico = null

				}

				this.formato.tablas[t].filas[f].columnas[c].mapeo_input = null
				this.formato.tablas[t].filas[f].columnas[c].mapeo_check = null
				this.formato.tablas[t].filas[f].columnas[c].mapeo_radio = null
				this.formato.tablas[t].filas[f].columnas[c].mapeo_fecha = null
				this.formato.tablas[t].filas[f].columnas[c].mapeo_textarea = null
			},

			previsualizarImg(e, t, f, c){
				
				const input = e.target;
		
				if(!input.files.length) return

				let file = input.files[0];

				let urlImg = URL.createObjectURL(file)
		
				this.getBase64Image(urlImg, t, f, c)
				
			},

			storeFormulario(){
				console.log('this.formato',this.formato)
				
				this.setUrl('formularios')
				this.overlay=true
				
				this.requestApi({
					method: 'POST',
					data: {
						typeStore:'storeFormulario',
						formato: JSON.stringify(this.formato),
						subServiciosCrmId: this.subServicioCrmId,
						servicioCrmId: this.servicioCrmId,
						formularioId: this.formularioId,
						uso: this.uso,
						nombre : this.nombreFormulario
					}
				}).then(res =>{

					console.log('res',res)

					this.getFormularios()

					this.alertNotification({
						param:{
							html: res.data.res.msg
						}
					})   

					this.closeDialogFormulario()

				}).catch(()=>{
					this.overlay=false
				}).then(()=>{
					this.overlay=false
				})

			},

			getBase64Image (url, t, f, c) {

				const img = new Image()

				img.setAttribute('crossOrigin', 'anonymous');
				
				img.onload = () => {

					const canvas = document.createElement("canvas")
					canvas.width = img.width
					canvas.height = img.height
					const ctx = canvas.getContext("2d")
					ctx.drawImage(img, 0, 0)
					const dataURL = canvas.toDataURL("image/png")
					console.log('dataURL',dataURL)
					
					this.formato.tablas[t].filas[f].columnas[c].contenido_img = dataURL
					
				}

				img.src = url

			},

			editFormulario(formulario){

				console.log('formulario',formulario)
				this.formato = JSON.parse(formulario.formato)
				this.servicioCrmId = formulario.servicio != null ? formulario.servicio.servicio_crm_id : null
				this.subServicioCrmId = formulario.sub_servicios.map(obj => { return obj.sub_servicio_crm_id})
				this.formularioId = formulario.formulario_servicio_crm_id
				this.nombreFormulario = formulario.nombre
				this.uso = formulario.vizualizar
				this.getCamposMapeo()
				this.openDialogFormulario()

			},

			updaEstadoFormulario(formulario){
				console.log('formulario',formulario)
				Vue.swal({
					html: `Esta seguro de ${formulario.estado ? 'deshabilitar' : 'habilitar'} el formulario?`,
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Aceptar",
					cancelButtonText: "Cerrar",
					...this.paramAlertQuestion,
				}).then((result) => {

					if (result.isConfirmed) {

						this.setUrl('formularios')
						this.overlay=true

						this.requestApi({
							method: 'POST',
							data: {
								typeStore:'updaEstadoFormulario',
								formularioId: formulario.formulario_servicio_crm_id,
							}
						}).then(res =>{

							console.log('res',res)

							this.getFormularios()

							this.alertNotification({
								param:{
									html: res.data.res.msg
								}
							})   

						}).catch(()=>{
							this.overlay=false
						}).then(()=>{
							this.overlay=false
						})
					}

				})

			},

			formCampoMapeo(){

				this.dialogCampoMapeo = true
				this.campoMapeo = null

			},

			getCamposMapeo(){

				this.setUrl('formularios')
				this.overlay=true

				this.requestApi({
					method: 'GET',
					data: {
						typeList:'mapeoCampoFomrulario'
					}
				}).then(res =>{

					this.camposMapeo = res.data._embedded.formularios

				}).catch(()=>{
					this.overlay=false
				}).then(()=>{
					this.overlay=false
				})

			},

			storeCampoMapeo(){

				if(!this.$refs.form_campo_mapeo.validate())
					return false

				this.setUrl('formularios')
				this.overlay=true

				this.requestApi({
					method: 'POST',
					data: {
						typeStore:'storeMapeoCampoFormulario',
						nombreCampo: this.campoMapeo,
					}
				}).then(res =>{

					console.log('res',res)

					this.getCamposMapeo()

					this.dialogCampoMapeo = false

					this.alertNotification({
						param:{
							html: res.data.res.msg
						}
					})   

				}).catch(()=>{

					this.overlay=false

				}).then(()=>{

					this.overlay=false

				})
				
			},

			beginResizing(td){
		
				this.start = td
				this.pressed = true
				this.startX = td.pageX
				this.startWidth = td.target.offsetWidth
				this.start.target.classList.add("resizing")
		
			},

			removeResizing(e){

				this.pressed = false
				this.start = e
				this.start.target.classList.remove("resizing")
				
			},

			resize(e, t, f, c){

				if(this.pressed){
								
					let w = (this.startWidth+(e.pageX-this.startX))+'px'
	
					this.start.target.style.width = w

					let tipo = this.start.target.id.split('-')[6]

					let width = w
				
					if(tipo == 'imagen')
						width = this.start.target.style.width

					let cantTr = document.getElementsByClassName(`tr-table-${t}`)
					
					let arrTrs = Array.from(cantTr)

					for (let tr = 0; tr < arrTrs.length; tr++){						

						let td = arrTrs[tr].getElementsByTagName('td')[c]

						td.style.width = width
						this.formato.tablas[t].filas[tr].columnas[c].width = width
												
					}										

				}
            	  
			},

			setSubServicios(){

				let ss  = this.servicioCrmId !=null ? this.originalSubServicios.filter(e => e.servicio_crm_id == this.servicioCrmId) : this.originalSubServicios
				this.subServicios = ss
				this.subServiciosOriginal = ss

			},

			desplegarMenu(){



			},

			moverTabla(sentido, tabla, index){
			
				let newIndex = sentido == 'arriba' ? (index-1) : (index+1)

				let nuevaTabla = this.formato.tablas[newIndex]

				Vue.set(this.formato.tablas, newIndex, tabla)

				Vue.set(this.formato.tablas, index, nuevaTabla)

			},

			accionTabla(accion, indexTabla, index, index2){

				console.log(accion, indexTabla, index)
				
				if(accion == 'agrega_columna_derecha'){

					this.formato.tablas[indexTabla].filas.forEach((_,f) => {
										
						this.formato.tablas[indexTabla].filas[f].columnas.splice((index+1), 0, {
							texto: false,
							contenido_texto:null,
							input:false,
							contenido_input:null,
							check: false,
							check_checked:false,
							radio_checked:false,
							radio:false,
							fecha:false,
							textarea:false,
							imagen:false,
							contenido_img:null,
							mapeo_input:null,
							mapeo_check:null,
							mapeo_radio:null,
							mapeo_fecha:null,
							mapeo_textarea:null,
							campo_predeterminado: false,
							mapeo_predeterminado:null,
							width:'0px',
							height:'0px',
							backgroundTd: '#fff',
							backgroundInput: '#fff',
							colorText: '#000',
							combo_dinamico:false,
							mapeo_combo_dinamico:null,
							combo_dinamico_seleccionado:null,
							nombre_combo_dinammico:null,
							alto_imagen:0,
							ancho_imagen:0
						})

					})

				}else if(accion == 'agrega_columna_izquierda'){

					this.formato.tablas[indexTabla].filas.forEach((_,f) => {
										
						this.formato.tablas[indexTabla].filas[f].columnas.splice(index, 0, {
							texto: false,
							contenido_texto:null,
							input:false,
							contenido_input:null,
							check: false,
							check_checked:false,
							radio_checked:false,
							radio:false,
							fecha:false,
							textarea:false,
							imagen:false,
							contenido_img:null,
							mapeo_input:null,
							mapeo_check:null,
							mapeo_radio:null,
							mapeo_fecha:null,
							mapeo_textarea:null,
							campo_predeterminado: false,
							mapeo_predeterminado:null,
							width:'0px',
							height:'0px',
							backgroundTd: '#fff',
							backgroundInput: '#fff',
							colorText: '#000',
							combo_dinamico:false,
							mapeo_combo_dinamico:null,
							combo_dinamico_seleccionado:null,
							nombre_combo_dinammico:null,
							alto_imagen:0,
							ancho_imagen:0
						})

					})

				}else if(accion == 'eliminar_columna'){

					this.formato.tablas[indexTabla].filas.forEach((_,f) => {
										
						this.formato.tablas[indexTabla].filas[f].columnas.splice(index, 1)

					})

				}else if(accion == 'cambiar_ancho'){

					this.formato.tablas[indexTabla].filas.forEach((_,f) => {
										
						let col = this.formato.tablas[indexTabla].filas[f].columnas[index]

						col.width = this.tamano+'px'

						this.formato.tablas[indexTabla].filas[f].columnas.splice(index, 1, col)

					})

				}else if(accion == 'agrega_fila_arriba'){

					let columnas = []

					this.formato.tablas[indexTabla].filas[index].columnas.forEach((_,c) => {

						columnas.push({
							texto: false,
							contenido_texto:null,
							input:false,
							contenido_input:null,
							check: false,
							check_checked:false,
							radio_checked:false,
							radio:false,
							fecha:false,
							textarea:false,
							imagen:false,
							contenido_img:null,
							mapeo_input:null,
							mapeo_check:null,
							mapeo_radio:null,
							mapeo_fecha:null,
							mapeo_textarea:null,
							campo_predeterminado: false,
							mapeo_predeterminado:null,
							width:'0px',
							height:'0px',
							backgroundTd: '#fff',
							backgroundInput: '#fff',
							colorText: '#000',
							combo_dinamico:false,
							mapeo_combo_dinamico:null,
							combo_dinamico_seleccionado:null,
							nombre_combo_dinammico:null,
							alto_imagen:0,
							ancho_imagen:0
						})

					})

					this.formato.tablas[indexTabla].filas.splice(index, 0, {columnas})
														
				}else if(accion == 'agrega_fila_abajo'){

					let columnas = []

					this.formato.tablas[indexTabla].filas[index].columnas.forEach((_,c) => {

						columnas.push({
							texto: false,
							contenido_texto:null,
							input:false,
							contenido_input:null,
							check: false,
							check_checked:false,
							radio_checked:false,
							radio:false,
							fecha:false,
							textarea:false,
							imagen:false,
							contenido_img:null,
							mapeo_input:null,
							mapeo_check:null,
							mapeo_radio:null,
							mapeo_fecha:null,
							mapeo_textarea:null,
							campo_predeterminado: false,
							mapeo_predeterminado:null,
							width:'0px',
							height:'0px',
							backgroundTd: '#fff',
							backgroundInput: '#fff',
							colorText: '#000',
							combo_dinamico:false,
							mapeo_combo_dinamico:null,
							combo_dinamico_seleccionado:null,
							nombre_combo_dinammico:null,
							alto_imagen:0,
							ancho_imagen:0
						})

					})

					this.formato.tablas[indexTabla].filas.splice(index+1, 0, {columnas})
														
				}else if(accion == 'eliminar_fila'){

					this.formato.tablas[indexTabla].filas.splice(index, 1)
				}else if(accion == 'cambiar_alto'){

					this.formato.tablas[indexTabla].filas.forEach((_,f) => {
										
						let col = this.formato.tablas[indexTabla].filas[f].columnas[index]

						col.height = this.tamano+'px'

						this.formato.tablas[indexTabla].filas[f].columnas.splice(index, 1, col)

					})

				}else if(accion == 'color_celda'){
										
					let col = this.formato.tablas[indexTabla].filas[index].columnas[index2]

					col.backgroundTd = this.colorCelda

					this.formato.tablas[indexTabla].filas[index].columnas.splice(index2, 1, col)

					this.verColorPickerCelda = false

				}else if(accion == 'color_input'){

					let col = this.formato.tablas[indexTabla].filas[index].columnas[index2]

					col.backgroundInput = this.colorInput

					this.formato.tablas[indexTabla].filas[index].columnas.splice(index2, 1, col)

					this.verColorPickerInput = false

				}else if(accion == 'color_texto'){

					let col = this.formato.tablas[indexTabla].filas[index].columnas[index2]

					col.colorText = this.colorTexto

					this.formato.tablas[indexTabla].filas[index].columnas.splice(index2, 1, col)

					this.verColorPickerTexto = false

				}else if(accion == 'tamano_imagen'){

					
					let ele = document.getElementById(`contenido-${indexTabla}-fila-${index}-columa-${index2}-img-imagen`)
					console.log('this.anchoImagen',this.anchoImagen,'this.altoImagen',this.altoImagen)
					ele.style.width = `${this.anchoImagen}px`
					ele.style.height = `${this.altoImagen}px`

					let col = this.formato.tablas[indexTabla].filas[index].columnas[index2]

					col.alto_imagen = this.altoImagen
					col.ancho_imagen = this.anchoImagen
					
					this.formato.tablas[indexTabla].filas[index].columnas.splice(index2, 1, col)
				}		
				
			},

			visualizaFormulario(form){

				console.log('form',form)

				this.formularioSeleccionado = form
				this.dialogVisualizaFormulario = true
			},

			searchSubServicios(clear){

				if(this.searchSubServicio.length && !clear){

					this.subServicios = this.subServicios.filter(e => {

						return e.internal_name.toLowerCase().includes(this.searchSubServicio.toLowerCase())

					})

				}else{

					this.subServicios = this.subServiciosOriginal

				}

			},

			openDialogComboDinamico(){

				this.setUrl('formularios')
				this.overlay=true

				this.requestApi({
					method: 'GET',
					data: {
						typeList:'detalleComboDinamico',
						comboDinamicoId: this.comboDinamicoId
					}
				}).then(res =>{

					try {
						console.log('res',res)
	
						this.camposMapeo = res.data.detail.mcf
						
						let mcd = this.mapeoComboDinamico.find(e => e.mapeo_campo_formulario_id == this.comboDinamicoId)
	
						if(typeof mcd  != 'undefined'){
	
							this.nombreComboDinamico = mcd.nombre
							this.tipoOrdenComboDinamico = mcd.tipo_orden_combo_dinamico
	
						}else{
	
							this.nombreComboDinamico = null
	
						}
	
						this.detalleComboDinamico = res.data.detail.detalles
						this.dialogCampoDinamico = true

					} catch (error) {
							console.log(error)
					}

				}).catch(()=>{
					this.overlay=false
				}).then(()=>{
					this.overlay=false
				})

			},

			closeDialogComboDinamico(){

				this.dialogCampoDinamico =false
				this.comboDinamicoId= null

			},

			guardaComboDinamico(){

				if(!this.$refs.campo_combo_dinamico.validate())
					return false

				this.setUrl('formularios')
				this.overlay=true

				this.requestApi({
					method: 'POST',
					data: {
						typeStore:'storeMapeoComboDinamico',
						comboDinamicoId: this.comboDinamicoId,
						nombreCampo: this.nombreComboDinamico,
						valor: this.valorComboDinamico,
						orden: this.tipoOrdenComboDinamico
					}
				}).then(res =>{
		
					this.comboDinamicoId = res.data.res.campoFormularioId

					this.openDialogComboDinamico()

					this.valorComboDinamico = null

					this.dialogCampoMapeo = false

					this.alertNotification({
						param:{
							html: res.data.res.msg
						}
					})   

				}).catch(()=>{

					this.overlay=false

				}).then(()=>{

					this.overlay=false

				})

			},

			updateDetalleCampoFormulario(dcd){

				this.setUrl('formularios')
			
				this.requestApi({
					method: 'POST',
					data: {
						typeStore:'updateDetalleCampoFormulario',
						dcdId: dcd.detalle_campo_formulario_id,
						usable: dcd.usable
					}
				}).then(res =>{

					this.alertNotification({
						param:{
							html: res.data.res.msg
						}
					})   

				}).catch(()=>{

				
				})

			},

			deleteDetalleCampoFormulario(dcd){

				this.setUrl('formularios')
			
				this.requestApi({
					method: 'POST',
					data: {
						typeStore:'deleteDetalleCampoFormulario',
						dcdId: dcd.detalle_campo_formulario_id,
					}
				}).then(res =>{

					this.openDialogComboDinamico()

					this.alertNotification({
						param:{
							html: res.data.res.msg
						}
					})   

				}).catch(()=>{

				
				})

			}

        },
		created(){

			this.getFormularios()

		}

    }

</script>


<style>

	.w-100{

		width: 100%;
	}

	/* .tabla-formato{
		width: 100%;
	} */

	.tabla-formato td{
		border: 1px solid silver;
		border-radius: 3px;
	}

	.card-widget {

		height: 40px;
		display: flex;
		align-items: center;

	}

	.v-label--active{

		background-color: white;
    	color: #37b34a;
	}

	.pointer-grab:hover {

		cursor: grab;

	}

	table.tabla-formato td.resizing {
		cursor: col-resize;
	}

	.td-thead{

		background-color: #f4f4f4;
		font-weight: 900;
		text-align: center;
		border: 1px solid #cdcdcd !important;

	}

	.input-tamano{

		width: 70px;
		height: 30px;
		border: 1px solid silver;

	}

	.header-search-ss {
		position:fixed;
		background-color: #f9f9f9;
		z-index: 1;
		height: 60px;
	}

	.btn-menu-ss {
		background-color: white; 
		border:none; 
		border-radius: 3px;
		color:#838383 !important;
		font-weight: 400;
	}
	

	#combo-dinamico div.v-input__append-outer{

		margin-top: 0px;

	}

</style>