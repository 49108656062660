<template>
    <v-card>
        <v-overlay :value="overlay" absolute>
			<v-progress-circular indeterminate size="64"></v-progress-circular>
		</v-overlay>
        <v-col cols="12">
            <v-col cols="12" class="text-center text-uppercase">
                <b>{{ nombre }}</b>
            </v-col>
            <table
                class="tabla-formato-visualizar my-2"
                style="width: 100%;"
                cellspacing="0"
                cellpadding="0"
                v-for="(tabla, t) in formato.tablas"
                :key="`tabla-${t}`"
            >
                <tbody>
                    <tr
                        v-for="(fila, f) in tabla.filas"
                        :key="f"
                    >
                        <td
                            v-for="(columna, c) in fila.columnas"
                            :key="c"
                            :id="`tabla-${t}-fila-${f}-columa-${c}`"
                            :class="`${columna.check || columna.radio || columna.contenido_img != null ? 'text-center' : ''}`"
                            :style="`width:${columna.width};height:${columna.height};background-color:${columna.backgroundTd}`"
                        >                           
                            <span
                                v-if="columna.texto && (!columna.check && !columna.radio)" 
                                class="px-2 text-left" 
                                :style="`width:${columna.width};color:${columna.colorText}`"
                            >
                                {{ columna.contenido_texto }}
                            </span>
                            <label 
                                v-if="columna.check" 
                                :for="`contenido-${t}-fila-${f}-columa-${c}-checkbox`"             
                            
                            >
                                {{ columna.contenido_texto }}
                            </label>
                            <label 
                                v-if="columna.radio" 
                                :for="`contenido-${t}-fila-${f}-columa-${c}-radio`"
                                :style="`color:${columna.colorText}`"
                            >
                                {{ columna.contenido_texto }}
                            </label>
                            <input 
                                v-if="columna.input"
                                type="text" 
                                :style="`border:1px solid silver;width: 98.5%;height: inherit;background-color:${columna.backgroundInput};color:${columna.colorText}`"
                                name=""
                                class=" mx-1 px-2" 
                                placeholder="Entrada de texto"
                                :id="`contenido-${t}-fila-${f}-columa-${c}-input`"
                                v-model="columna.contenido_input"                                
                            >
                            <div>
                                <input 
                                    v-if="columna.check"
                                    type="checkbox" 
                                    name=""
                                    class="my-1 mx-1" 
                                    :id="`contenido-${t}-fila-${f}-columa-${c}-checkbox`"
                                    :value="columna.contenido_texto"    
                                    :style="`width: 17px;height: 17px;accent-color:${columna.backgroundInput}`"
                                    v-model="columna.check_checked"
                                >                                 
                                <input 
                                    v-if="columna.radio"
                                    type="radio" 
                                    :id="`contenido-${t}-fila-${f}-columa-${c}-radio`"
                                    :name="`radio-${t}-fila-${f}-${columna.mapeo_radio}`"
                                    class="my-1 mx-1"
                                    :value="columna.contenido_texto"  
                                    :style="`width: 17px;height: 17px;accent-color:${columna.backgroundInput}`"
                                    @click="checkRadio($event,t,f,c)"
                                    :checked = "columna.radio_checked"
                                >
                            </div>
                            <textarea
                                v-if="columna.textarea"
                                :id="`contenido-${t}-fila-${f}-columa-${c}-textarea`"
                                type="textarea" 
                                name=""
                                placeholder="Área de texto"
                                class="my-1 mx-1  px-2"
                                :style="`width: 97%;height: 97%; border: 1px solid #dedede;background-color:${columna.backgroundInput};color:${columna.colorText}`"
                                v-model="columna.contenido_input"
                            ></textarea>
                            <input 
                                v-if="columna.fecha"
                                :id="`contenido-${t}-fila-${f}-columa-${c}-fecha`"
                                type="date" 
                                name=""
                                :style="`border:1px solid #dedede;width:${columna.width};background-color:${columna.backgroundInput};color:${columna.colorText}`"
                                class="my-1 mx-1"
                                v-model="columna.contenido_input"
                            >
                            <v-autocomplete
                                v-if="columna.combo_dinamico"																								
                                :items="columna.optionsDinamicos"
                                v-model="columna.combo_dinamico_seleccionado"
                                :id="`contenido-${t}-fila-${f}-columa-${c}-combo-dinamico`"
                                item-value="nombre"
                                item-text="nombre"
                                dense
                                outlined
                                :label="columna.nombre_combo_dinammico"
                                :hide-details="true"
                                class="me-2 white mt-1"
                                clearable
                            />
                            <div 
                                v-if="columna.contenido_img" 
                                class="text-center mt-2 mr-2"
                            >
                                <v-btn 
                                    x-small
                                    class="text-center" 
                                    color='success' 
                                    @click="clearCanvas(t,f,c)"
                                >
                                    <v-icon>mdi-reload</v-icon> Limpiar imagen
                                </v-btn>
                            </div>                            
                            <canvas 
                                :id="`pizarra-${t}-${f}-${c}`"
                                v-if="columna.contenido_img != null"
                                :width="columna.ancho_imagen"
                                :height="columna.alto_imagen"                                  
                                @mouseover="setCanvas"
                                @mousedown="beginDrawing" 
                                @mousemove="draw"                                                                         
                                @mouseup="stopDrawing"
                                @touchmove="draw" 
                                @touchstart="setCanvas($event), beginDrawing($event)" 
                                @touchend="stopDrawing"
                            >
                            </canvas>
                            <div 
                                v-if="columna.campo_predeterminado" 
                                class="text-center"
                            >
                                <span :style="`color:${columna.colorText}`"> {{ columna.mapeo_predeterminado }}</span>
                            </div>
                        </td>												
                    </tr>
                </tbody>										
            </table>
        </v-col>
        <v-card-actions>
            <v-col 
                cols="12" 
                class="text-center"
            >
                <v-btn
                    class="mx-1"
                    color="success"
                    @click="storeFormulario"
                    v-if="guardaRegistro"
                >
                    <v-icon>mdi-floppy</v-icon> Guardar
                </v-btn>
                <v-btn
                    class="mx-1"
                    color="default"
                    @click="$emit('close')"
                >
                    <v-icon>mdi-cancel</v-icon> Cerrar
                </v-btn>
            </v-col>
        </v-card-actions>
    </v-card>
</template>

<script>

import { mapState, mapMutations, mapActions } from "vuex"

export default {
    name:'VisualizaFormularioComponent',
    props:{
        formulario:{
            type: Object,
            require: true
        },
        ordenServicioId:{
            type: String,
            default:''
        },
        nuevo:{
            type:Boolean,
            default:true
        },
        editar:{
            type:Boolean,
            default:false
        },
    },    
    data: () => ({
        servicioCrmId:null,
        selectedFormId:null,
        formularios:[],
        mapeoComboDinamico:[],
        x:0,
        y:0,
        nombre:null,
        canvas:null,
        isDrawing: false,
        overlay:false,
        formato:{
            tablas:[]
        },
    }),
    computed: {

        ...mapState("crm", ["loadingBtn", "paramAlertQuestion"]),

        ...mapState("master", ["loadingTable", "tenantId", "user", "urlApi"]),

        guardaRegistro() {

            return this.nuevo || this.editar

        }

    },
    methods:{

        ...mapMutations("master", ["setUrl","setLoadingTable","setTitleToolbar","setTenantId"]),

        ...mapActions("master", ["requestApi", "alertNotification"]),

        renderizaFormulario(){

            this.nombre = this.formulario.nombre
            
            let formato = JSON.parse(this.formulario.formato)
            
            let campoPredeterminado = []
            let comboDinamicoTag = []

            let tablaCamposPredeterminados = []
            let tablaCombosDinamicos = []

            formato.tablas.forEach((tabla, t) =>{

                tabla.filas.forEach((fila, f) =>{

                    fila.columnas.forEach((columna, c) => {

                        if(columna.mapeo_predeterminado){

                            campoPredeterminado.push(columna.mapeo_predeterminado)

                            tablaCamposPredeterminados.push({
                                tabla: t,
                                fila: f,
                                columna : c,
                                campo: columna.mapeo_predeterminado
                            })

                        }

                        if(columna.combo_dinamico){

                            comboDinamicoTag.push(columna.mapeo_combo_dinamico)

                            tablaCombosDinamicos.push({
                                tabla: t,
                                fila: f,
                                columna : c,
                                tag: columna.mapeo_combo_dinamico
                            })

                        }
                            
                    })                   

                })               

            })           

            if( (campoPredeterminado.length || comboDinamicoTag.length)){

                this.setUrl('formularios')
				this.overlay=true

				this.requestApi({
					method: 'GET',
					data: {
						typeList:'datosCamposPredeterminado',
                        ordenServicioId: this.ordenServicioId,
                        comboDinamicoTag,
                        campoPredeterminado
					}
				}).then(res =>{

					try {
                        console.log('res',res.data.detail)
                        
                        formato.tablas.map((tabla, tab) =>{
    
                            tabla.filas.forEach((fila,fil) =>{
    
                                fila.columnas.forEach((columna,col) => {
    
                                    let objCampo = tablaCamposPredeterminados.find(e => e.tabla == tab && e.fila == fil && e.columna == col)
    
                                    if(typeof objCampo != 'undefined')
                                        columna.mapeo_predeterminado = res.data.detail.datos_mapeo[objCampo.campo]
    
                                    let objComboDinamico = tablaCombosDinamicos.find(e => e.tabla == tab && e.fila == fil && e.columna == col)
                                    
                                    if(typeof objComboDinamico != 'undefined'){
    
                                        let optionsDinamicos = res.data.detail.combos_dinamicos.filter(e => e.tag == objComboDinamico.tag) 
                        
                                        if(optionsDinamicos.length){

                                            columna.nombre_combo_dinammico = optionsDinamicos[0].nombre_combo_dinammico
        
                                            columna.optionsDinamicos = optionsDinamicos.sort((a,b) => {
                                                
                                                if(optionsDinamicos[0].tipo_orden_combo_dinamico){
        
                                                    if(a.nombre.toString().toLowerCase() > b.nombre.toString().toLowerCase()) { return -1 }
        
                                                }else{
        
                                                    if(a.nombre.toString().toLowerCase() < b.nombre.toString().toLowerCase()) { return -1 }
        
                                                }
        
                                            })

                                        }
                                        
                                    }
    
                                })
    
                            })
    
                            return tabla
    
                        })
    
                        this.formato = formato
                        this.overlay = false
                        
                    } catch (error) {
                        console.log('error',error)
                    }
				}).catch(()=>{
					this.overlay=false
				}).then(()=>{
					this.overlay=false
				})

            }else{

                this.formato = formato

            }

            formato.tablas.forEach((tabla,t)=>{

                tabla.filas.forEach((fila,f)=>{

                    fila.columnas.forEach((columna,c)=>{

                        if(this.nuevo){

                            columna.radio_checked = false
                            columna.check_checked = false

                        }
                                                
                        if(columna.contenido_img != null){

                            setTimeout(()=>{

                                const canvas = document.getElementById(`pizarra-${t}-${f}-${c}`)
                            
                                const ctx = canvas.getContext("2d")

                                let image = new Image()

                                image.onload = function() {

                                    ctx.drawImage(image,10,10,columna.ancho_imagen,columna.alto_imagen)

                                }

                                image.src = `${this.urlApi}img/formularios/${columna.contenido_img}`

                            },2000)

                        }

                    })

                })                  

            })

        },

        setCanvas(e){
  
            this.canvas = e.target.getContext('2d')

        },

        drawLine(x1, y1, x2, y2) {
                        
            let ctx = this.canvas
            ctx.beginPath()
            ctx.strokeStyle = 'black'
            ctx.lineWidth = 2
            ctx.moveTo(x1+20, y1)
            ctx.lineTo(x2+20, y2)
            ctx.stroke()
            ctx.closePath()

        },

        draw(e) {
      
            if(this.isDrawing) {


                console.log('draw',e)
                
                let touch = e.touches

                if(typeof touch !='undefined'){

                    console.log('touch',touch[0].clientX, touch[0].clientY)

                    let rect = this.canvas.canvas.getBoundingClientRect()

                    let xx = touch[0].clientX-rect.left
                    let yy =  touch[0].clientY-rect.top

                    this.drawLine(this.x, this.y, xx, yy)
                    this.x = touch[0].clientX-rect.left
                    this.y = touch[0].clientY-rect.top

                }else{

                    this.drawLine(this.x, this.y, e.offsetX, e.offsetY)
                    this.x = e.offsetX
                    this.y = e.offsetY

                }
                
            }

        },

        beginDrawing(e) {

            document.getElementsByTagName('html')[0].style.overflowY= 'hidden'
            document.getElementsByTagName('html')[0].style.overflowX= 'hidden'

            console.log('beginDrawing', e)

            let touch = e.touches

            if(typeof touch !='undefined'){

                let rect = this.canvas.canvas.getBoundingClientRect()
                this.x = touch[0].clientX-rect.left
                this.y = touch[0].clientY-rect.top

            }else{

                this.x = e.offsetX;
                this.y = e.offsetY;

            }
           
            this.isDrawing = true

        },

        stopDrawing(e) {          

            if (this.isDrawing) {

                document.getElementsByTagName('html')[0].style.overflowY = 'auto'
                document.getElementsByTagName('html')[0].style.overflowX = 'auto'
               
                this.drawLine(this.x, this.y, e.offsetX, e.offsetY)
                this.x = 0
                this.y = 0
                this.isDrawing = false

            }

        },

        clearCanvas(t,f,c){

            let ctx = document.getElementById(`pizarra-${t}-${f}-${c}`).getContext('2d')

            let image = new Image()
            let this2 = this

            image.onload = function() {

                let col = this2.formato.tablas[t].filas[f].columnas[c]
             
                ctx.drawImage(image, 10,10,col.alto_imagen,col.ancho_imagen)

            }

            image.src =  `${this.urlApi}img/formularios/${this.formato.tablas[t].filas[f].columnas[c].contenido_img}`
            
        },

        storeFormulario(){

            this.$emit('store', this.formato)
        },

        checkRadio(e,t,f,c){

            let cols = this.formato.tablas[t].filas[f].columnas

            cols.filter(col => col.radio == true && col.mapeo_radio == e.target.name.split('-')[4]).forEach(obj => {

                let index = this.formato.tablas[t].filas[f].columnas.indexOf(obj)
                this.formato.tablas[t].filas[f].columnas[index].radio_checked= false

            })
            
            this.formato.tablas[t].filas[f].columnas[c].radio_checked = true

        }

    },
    created(){

        this.renderizaFormulario()
        
    }

}
</script>

<style>

	.w-100{

		width: 100%;
	}

	.tabla-formato-visualizar{
		width: 100%;
	}

	.tabla-formato-visualizar td{
		border: 1px solid silver;
		border-radius: 3px;
	}

	.card-widget {

		height: 40px;
		display: flex;
		align-items: center;

	}

	.v-label--active{

		background-color: white;
    	color: #37b34a;
	}

    .mause-pencil:hover{

        cursor: pointer;
    }

    /* html {
        overflow: hidden;
    } */
</style>